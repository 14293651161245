<template>
  <div class="onboard-terms onboard-terms-page mt-10">
    <vx-card class="accordin-card mb-5 terms-card">
      <div class="vx-card__body bsv p-0">
        <vs-collapse accordion>
          <vs-collapse-item open>
            <div slot="header">
              <div class="flex flex-row items-center">
                <img class="icon-control" :src="edit" alt="home" />
                <h3 class="pl-4 fs-control font-medium">Users</h3>
              </div>
            </div>
            <vs-row>
              <div>
                <p class="font-normal">
                  You'll have access to manage your approved {{ appName }} account via our web portal (even if you've initiated your onboarding from another software platform). To do so, we'll need you to confirm <i>at least one</i> <b>admin</b> level user for portal access below, and you can also add any other “users” you wish at the same time.
                  We'll send emails to each user added here with details on how to login.
                </p>
                <ul class="mt-8 list-disc">
                  <li><b>Admin</b> users can change account settings, manage users, request new products, etc (typically an account holder or 1-2 authorised staff)</li>
                  <li><b>Users</b> can create payment & funding requests, view payments, etc (all other users of the web portal)</li>
                </ul>
              </div>
              <div class="mt-10 mb-5" v-for="(user, key) in users" :key="key">
                <h3>User {{ key + 1 }}</h3>
                <hr class="line-hr" />

                <div class="flex items-start mt-6">
                  <div class="flex items-center justify-center">
                    <div class="input-grp">
                      <label class="text-base p-0 w-full text-dark-blue">Full name <span class="mid-blue align-top">*</span></label>
                      <vs-input v-model="user.fullName" data-vv-as="full name" data-vv-validate-on="blur" v-validate="'required'" :name="'fullName'+key" class="w-full surcharge-top" />
                      <span v-show="errors.has('fullName'+key)"class="text-danger text-sm">{{ errors.first("fullName"+key) }}</span>
                    </div>
                    <div class="input-grp">
                      <label class="text-base p-0 w-full text-dark-blue">Email address <span class="mid-blue align-top">*</span></label>
                      <vs-input v-model="user.email" v-validate="'required|email'" :name="'email'+key" data-vv-as="email address" data-vv-validate-on="blur" class="w-full surcharge-top" />
                      <span v-show="errors.has('email'+key)" class="text-danger text-sm">{{ errors.first("email"+key) }}</span>
                    </div>
                    <div>
                      <label class="text-base p-0 w-full text-dark-blue">Role <span class="mid-blue align-top">*</span></label>
                      <vs-select v-validate="'required'" :name="'role'+key" class="w-full rounded mt-2" data-vv-as="role" v-model="user.role">
                        <vs-select-item v-for="(role, index) in roles" :key="index + 0.1" :text="role" :value="role"></vs-select-item>
                      </vs-select>
                      <span v-show="errors.has('role'+key)" class="text-danger text-sm">{{ errors.first("role"+key) }}</span>
                    </div>
                    <div>
                      <trash-icon size="1x" class="ml-4 mt-8" @click="deleteUser(key)" v-if="users.length > 1"></trash-icon>
                    </div>
                  </div>
                </div>
              </div>
            </vs-row>
            <div class="mt-5">
              <span @click="addUser" class="text-sm flex item-center mt-1"><plus-icon size="1.5x" class="button-icon mr-3"></plus-icon> Add user</span>
            </div>
          </vs-collapse-item>
        </vs-collapse>
      </div>
    </vx-card>
    <div class="mt-10 flex w-full flex-wrap justify-end ob-btn-container">
      <vs-button flat size="large" v-round class="ml-auto only-border-btn" @click="saveForm()">Save</vs-button>
      <vs-button size="large" v-round class="ml-8 next-color" @click="saveForm('submit')">Submit</vs-button>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { PlusIcon, TrashIcon } from "vue-feather-icons";
const edit = require("@/assets/images/edit.svg");

export default {
  components: { PlusIcon, TrashIcon },

  data() {
    return {
      appName: process.env.VUE_APP_NAME == "FLipPay" ? "FlipPay" : "Rello",
      edit: edit,
      defaultUser: {
        fullName: "",
        email: "",
        role: ""
      },
      users: [],
      roles: ["Admin", "User"],
      id: null,
    }
  },

  methods: {
    ...mapActions("onboardMerchant", ["fetchOnboardDetailWithId", "submitApplication"]),

    getDetails() {
      this.$vs.loading();
      this.fetchOnboardDetailWithId(this.id).then((result) => {
        const onboardDetail = result.data.data;

        if (onboardDetail && onboardDetail.users && onboardDetail.users.length) {
          this.users = [...onboardDetail.users];
        } else if (onboardDetail.onboardingRequest) {
          this.users = [
            {
              fullName: onboardDetail.onboardingRequest.contactName || "",
              email: onboardDetail.onboardingRequest.email || "",
              role: "Admin",
            }
          ];
        }
        this.$vs.loading.close();
      }).catch((ex) => {
        this.$vs.loading.close();
        this.$vs.notify({
          title: "Error",
          text: (ex.response && ex.response.data && ex.response.data.message) ? ex.response.data.message : "Unable to load correctly please reload this page.",
          color: "danger",
          iconPack: "feather",
          position: "top-right",
        });

        if (ex.response.status == 422) {
          this.$router.push({ name: "onboarding-authenticate" });
        }
      });
    },

    addUser() {
      this.users.push({ ...this.defaultUser });
    },

    deleteUser(index) {
      this.users.splice(index, 1);
    },

    async saveForm(type = "save") {
      let valid = true;

      if (type == "submit") {
        valid = await this.$validator.validateAll();
      }

      if (!valid) {
        return;
      }

      valid = this.users.some(item => item.role == "Admin");

      if (!valid) {
        this.$vs.notify({
          title: "User",
          text: "Please select at least one adminsitrator for your merchant account",
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger",
        });

        return;
      }

      this.$vs.loading();

      const data = {
        id: this.id,
        users: this.users,
        type: type
      };

      await this.submitApplication(data).then((result) => {
        this.$vs.loading.close();

        if (result.data.data && type == "submit") {
          this.$router.push({
            name: "SubmissionApplication",
            params: {
              introducerRequestedId: this.id,
            },
          });
        }
      }).catch((ex) => {
        if (ex.response.status == 422) {
          this.$vs.notify({
            title: "Email",
            text: ex.response.data.message,
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger",
          });

          if (ex.response.data.data) {
            ex.response.data.data.forEach(item => {
              this.errors.add({
                field: item,
                msg: "Email already used",
              });
            });
          }
        }
        this.$vs.loading.close();
      });
    },
  },

  created() {
    this.id = this.$route.params.introducerRequestedId;
    this.getDetails();
  }
}
</script>

